(function() {

// Get tabsName as array from text of collection of DOM elements
const tabsName = Array.from(
    document.querySelectorAll('.swiper-pagination span')).map(
    slide => slide.textContent
);

const swiper = new Swiper('.swiper', {
    loop: false,
    pagination: {
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + tabsName[index] + '</span>';
        },
        clickable: true,
    },
    slidesPerView: 1,

});



const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
    const itemToggle = this.getAttribute('aria-expanded');
    for (i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
    }
    if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
    }
}
items.forEach(item => item.addEventListener('click', toggleAccordion));

})();